import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: 'G-N638S5EZKP',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: 'd3fd9994-8917-4f62-aad5-01a23ff348bc',
  footerLogoId: 'eca63680-1e51-4239-83a1-9100bd996af8',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&display=swap',
  defaultTitle: 'Gamesforgirls',
  titleTemplate: 'or-site-gamesforgirls | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '8116116d-8af7-422d-b344-7fa1270b9b2e',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      strategy: 'afterInteractive',
      charset: 'UTF-8',
      'data-domain-script': '8116116d-8af7-422d-b344-7fa1270b9b2e'
    },
    {
      id: 'ot-callback-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      // Todo: Nunjucks the GA tracking IDs.
      src: `https://www.googletagmanager.com/gtag/js?id=G-N638S5EZKP`,
      strategy: 'afterInteractive'
    },
    {
      id: 'gtag-datalayer-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N638S5EZKP', {
              page_path: window.location.pathname,
            });
          `
      }
    },
  ],
  links: [
  ],
};